<template>
  <div class="app-box">
    <x-scroll-view :bottom="0.66">
      <x-form-box>
        <x-form-label label="药品名称" required :bindValue="xhrData.medicineName" @change="medicineChange"></x-form-label>
        <!-- <x-form-input label="新建药品名称" v-model="xhrData.otherMedicineName"></x-form-input> -->
        <x-form-input label="通用名" v-model="xhrData.commonName"></x-form-input>
        <x-form-input label="规格" v-model="xhrData.specification"></x-form-input>
        <x-form-input label="单位" v-model="xhrData.unit"></x-form-input>
        <x-form-input label="生产厂家" v-model="xhrData.manufacturer"></x-form-input>
        <x-form-select label="采购渠道" :slots="PurchasingChannelEnum" @change="purchasingChange" :bindValue="xhrData.purchasingChannelType"></x-form-select>
        <!-- <x-form-input label="新建采购渠道" v-model="xhrData.otherPurchasingChannel"></x-form-input> -->
        <x-form-input v-if="customerType==1000" label="月使用量" v-model="xhrData.monthlyUsageAmount"></x-form-input>
        <x-form-input v-if="customerType==2000" label="月销量" v-model="xhrData.monthlySales"></x-form-input>
      </x-form-box>
    </x-scroll-view>
    <x-footer-box :type="type" :isDisabledBtn="isDisabledBtn" @saveEvent="saveClick" @delEvent="delClick"></x-footer-box>
  </div>
</template>

<script>
import formBox from "@/components/formControl/formBox";
import formInput from "@/components/formControl/formInput";
import formSelect from "@/components/formControl/formSelect";
import footerBox from "@/components/footerBox";
import formLabel from "@/components/formControl/formLabel";
import scrollView from "@/components/scrollView";
import { addMedicine, getMedicine, updateMedicine, deleteMedicine } from "@/api/customerCenter";
import { ajaxBack } from "@/script/mixins/ajaxBack";
import { cachePage } from "@/script/mixins/cachePage";

export default {
  name: "customerCenterDetailDrugsInfoEdit",
  data () {
    return {
      customerType: this.$route.params.customerType,
      isDisabledBtn: true,
      type: this.$route.params.type,
      xhrData: {
        customerId: this.$route.params.id,
        medicineName: null,
        medicineNameId: null,
        otherMedicineName: null,
        commonName: null,
        specification: null,
        unit: null,
        manufacturer: null,
        purchasingChannel: null,
        purchasingChannelType: null,
        otherPurchasingChannel: null,
        monthlySales: null,
        monthlyUsageAmount: null
      },
      PurchasingChannelEnum: [{
        flex: 1,
        values: [],
        textAlign: "center",
        defaultIndex: 0
      }]
    };
  },
  mixins: [cachePage, ajaxBack],
  created () {
    document.title = "";
    this.type == "new" && (document.title = "药品信息");
    this.setEnum();
  },
  methods: {
    setEnum () {
      const configJson = JSON.parse(sessionStorage.getItem("configJson")).PurchasingChannelEnum;
      Object.keys(configJson).forEach(key => {
        this.PurchasingChannelEnum[0].values.push({
          code: key,
          name: configJson[key]
        });
      });
      this.type == "edit" && this.getData();
    },
    getData () {
      getMedicine({
        medicineId: this.$route.params.id
      }).then(xhr => {
        this.xhrData = xhr.data;
        document.title = this.xhrData.medicineName;
      });
    },
    purchasingChange (item) {
      this.xhrData.purchasingChannelType = item.code;
      this.xhrData.purchasingChannel = item.name;
    },
    medicineChange () {
      this.$router.push({
        path: "/searchPage/drugs"
      });
    },
    saveClick () {
      if (!this.isDisabledBtn) {
        if (this.type == "new") {
          addMedicine(this.xhrData).then(xhr => {
            this.ajaxBack(xhr, "添加成功！");
          });
        } else {
          updateMedicine(this.xhrData).then(xhr => {
            this.ajaxBack(xhr, "修改成功！");
          });
        }
      }
    },
    delClick () {
      deleteMedicine({
        medicineId: this.$route.params.id
      }).then(xhr => {
        this.ajaxBack(xhr, "删除成功！", -2);
      });
    }
  },
  watch: {
    xhrData: {
      handler (newVal) {
        this.isDisabledBtn = !!newVal.medicineNameId;
      },
      deep: true
    },
    $route () {
      const searchValue = this.$store.getters.getSearchValue;
      if (searchValue) {
        this.xhrData.medicineName = searchValue.medicineName;
        this.xhrData.medicineNameId = searchValue.medicineNameId;
        this.xhrData.commonName = searchValue.commonName;
        this.xhrData.specification = searchValue.specification;
        this.xhrData.unit = searchValue.unit;
        this.xhrData.manufacturer = searchValue.manufacturer;
        this.$store.commit("setSearchValue", null);
      }
    }
  },
  components: {
    [scrollView.name]: scrollView,
    [formInput.name]: formInput,
    [formBox.name]: formBox,
    [formSelect.name]: formSelect,
    [footerBox.name]: footerBox,
    [formLabel.name]: formLabel
  }
};
</script>

<style scoped></style>
